import React, { createContext, useContext, useState } from 'react';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
// Create a Context for the modal
const ModalContext = createContext();

// Create a provider component
export const ModalProvider = ({ children }) => {
  const projectId = '2aca272d18deb10ff748260da5f78bfd';

  const polygonMainnet = {
    chainId: 137,
    name: 'Polygon Mainnet',
    currency: 'MATIC',
    explorerUrl: 'https://polygonscan.com/',
    rpcUrl: 'https://polygon-rpc.com/',
  };

  const bscMainnet = {
      chainId: 56, // Mainnet chainId for Binance Smart Chain
      name: 'Binance Smart Chain Mainnet',
      currency: 'BNB', // BNB is the currency symbol for BSC mainnet
      explorerUrl: 'https://bscscan.com/', // Mainnet explorer URL for BSC
      rpcUrl: 'https://bsc-dataseed.binance.org/' // Example RPC URL for BSC mainnet (replace if needed)
  };

  const metadata = {
    name: 'Skilleareum',
    description: 'Skilleareum Ecosystem',
    url: 'https://skilleareum.ai/',
    icons: ['https://skilleareum.ai/favicon.png']
  };

  const modal = createWeb3Modal({
    ethersConfig : defaultConfig({metadata, enableInjected: true, enableEIP6963: true, rpcUrl: 'https://polygon-rpc.com/', defaultChainId: 137}),
    chains: [polygonMainnet,bscMainnet],
    projectId,
    enableAnalytics: true,
    allWallets: "SHOW"
  });

  return (
    <ModalContext.Provider value={modal}>
      {children}
    </ModalContext.Provider>
  );
};

// Custom hook to use the modal context
export const useModal = () => {
  return useContext(ModalContext);
};
