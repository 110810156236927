import React from 'react';
// import { Spinner } from "@material-tailwind/react";


const DataSpinners = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center w-screen h-screen bg-black backdrop-blur-md">
      <div className='flex items-center gap-5'>
   <div class="relative flex justify-center items-center">
    <img src='../assets/Skilleareum-Gif-ezgif.com-gif-to-webp-converter.gif'/>
</div><br/>
   <p className='text-white text-2xl font-bold'>Loading....</p>
   </div>
</div>
  );
};

export default DataSpinners; 